import styled from "styled-components"
import { useEffect, useState } from "react";
import seta from "../midia/seta_black.png"


function Header() {

    const [hasScrolled, setHasScrolled] = useState(false);
    const [button, setButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const triggerPoint = 0; 

            
            if (scrollPosition > triggerPoint) {
                setHasScrolled(true);
                
                console.log(true)
            } else {
                setHasScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    function buttonClick(){
        setButton(!button)
    }


    return(
        
        <div >
            <DivR style={hasScrolled ? {backgroundColor: 'white', color: 'black'} : {background: 'trasparent'}}>
                <Base style={ button ?  {borderBottom: '0px'} : {borderBottom: '.2px solid rgba(255, 255, 255, .2)'}}>
                    <li onClick={buttonClick}>
                        <div class="burger">

                            <div class="line" style={button ? {
                                backgroundColor: 'black', 
                                rotate: '130deg',
                                marginBottom: '-2px',
                                marginTop: '10px',
                                padding: '1px'

                            } 
                            : {backgroundColor: button ? 'black' : hasScrolled ? 'black' : 'white'}}></div>
                            
                            <div class="line" style={button ? {display: 'none'} 
                            : {backgroundColor: button ? 'black' : hasScrolled ? 'black' : 'white'}}></div>
                            
                            <div class="line" style={button? {
                                backgroundColor: 'black', 
                                rotate: '-130deg',
                                marginBottom: '100px',
                                padding: '1px'
                            } 
                            : {backgroundColor: button ? 'black' : hasScrolled ? 'black' : 'white'}}></div>
                        
                        </div>
                    </li>

                    <li style={button || hasScrolled ? {color: 'black'} : {color: 'white'}}>HarpiHost Web Sites</li>
                    <li></li>
                </Base>
            </DivR>
            
            <Menu style={button ? {height: '300px'} : {height: '0px'}}>

                    <ul>
                        <p>Contato</p>
                        <li>
                            <a href="https://wa.me/5524998210584">
                                <span>Peça seu orçamento</span>
                            </a>
                        </li>
                    </ul>

                    <ul>
                        <p>Portfólio</p>
                        <li>
                            <a>
                                <span>Clientes</span>
                            </a>
                        </li>
                    </ul>



            </Menu>

        </div>
    )
}




const Menu = styled.div`
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 11;
    overflow: hidden;
    transition: 1s;
    background-color: white;
    display: flex;
    gap: 10rem;
    padding-left: 15%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);


    p {
        font-weight: 600;
        font-size: 1.1rem;
        margin: 0px;
        padding: .5rem .0rem;
        color: rgba(0, 0, 0, 0.2);
    }
    
    ul {
        margin-top: 100px;
        list-style: none;

        li {
            border-bottom: 2px solid white;
            transition: .3s;

            &::after {
                content: "";
                display: block;
                height: 2px;
                width: 0px;
                background-color: black;
                transition: .3s;
            }

            &:hover::after {
                    width: 70px;
                }

        }

        a {
            padding: .3rem 0;
            font-size: 1.5rem;
            font-weight: 500;
            display: flex;
            gap: .5rem;
            padding: .5rem 0rem;
            border-radius: .5rem;
            text-decoration: none;
            color: black;
        }
    }




    @media (max-width: 450px) {
        display: grid;
        gap: 0px;
        justify-content: start;
        align-content: start;
        padding: 0px;

        ul {
            margin: 0px;

            &:first-child {
                padding-top: 5rem;
            }

            a {
                gap: 0px;

                img {
                    width: 25px;
                }
            }
        }

       

        span {
            font-size: 20px;
        }

        p {
            font-size: 16px;
        }
        
    }

`

const DivR = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    position: fixed;
    color: white;
    transition: .3s;
    z-index: 13;
    font-weight: bold;


    .burger {
        width: 21px;
        height: 18px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
    }

    .line {
        width: 100%;
        height: 1px;
        border-radius: 2px;
        transition: all 0.3s ease;
    }

    .burger:hover .line {
        background-color: black;
    }



`

const Base = styled.ul`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 2rem 14rem;
    list-style: none;
    top: 0px;

    @media (max-width: 1300px) {
        padding: 1.8rem 5rem;
    }


    @media (max-width: 450px) {
        padding: 1.5rem 2rem;
        flex-direction: row-reverse;
    }

`


export default Header