import styled from "styled-components"
import img from "../paraty.jpg"
import { useEffect, useState } from "react";
import hh from '../midia/harccpi.png'


function About() {


    return (

        <Base>
            <div className="first">
                <p>Expanda seu alcance e eleve seu profissionalismo: Desenvolva um site com a <strong>HarpiHost</strong></p>
            </div>
            <div>
                <img src={hh} width={250}/>
            </div>
        </Base>

    )
}

const Base = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    margin: 0 auto;
    align-items: center;

    .first {
        width: 31ch;
        font-size: 2.2rem;
        font-family: "Raleway", sans-serif;
        line-height: 3.3rem;
        padding-left: 2rem;
    }

    @media (max-width: 1300px) {
        max-width: 1100px;
        .first {
            padding-left: 3rem;
        }
    }

    @media (max-width: 450px) {
        padding:  40px 0 0 0;

        .first {
            font-size: 2rem;
            line-height: 44px;
            width: 15ch;
            padding-left: 2rem;
        }

        img {
            width: 170px;
            display: none;
        }

        * {
            padding: 0px;
            margin: 0px;
        }

    }
`


export default About