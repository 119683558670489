import React from 'react';  
import styled from 'styled-components';
import logo from './logo.svg';
import './App.css';
import Header from './header/Header';
import Img from './paraty.jpg';
import Home from './home/Home';
import About from './about/About';
import Portfolio from './portfolio/Portfolio'
import Rodape from './rodape/Rodape'
import HowWorks from './howWorks/HowWorks';
import TypeSites from './typesSites/TypesSites'
import { Helmet } from 'react-helmet';

function App() {
  return (
    <AppContainer>

      <Helmet>
        <title>HarpiHost Paraty</title>
        <meta name="description" content='Empresa localizada em Paraty - RJ, especializada no desenvolvimento de sites profissionais.' />
        <meta name='keywords' content='Web Sites Paraty RJ, Sites Paraty - RJ, Sites Profissionais Paraty RJ, Criação de Sites Paraty RJ, Desenvolvimento de Sites Paraty RJ, Design de Sites Paraty RJ, Agência de Web Paraty RJ, Sites Responsivos Paraty RJ, Web Design Paraty RJ, Sites para Empresas Paraty RJ, Desenvolvimento Web Paraty RJ, Web Designer Paraty RJ, Criar Sites Paraty RJ, Websites Profissionais Paraty RJ, Serviços de Web Paraty RJ, Construção de Sites Paraty RJ, Criação de Sites para Negócios Paraty RJ, Sites Customizados Paraty RJ, Soluções Web Paraty RJ, Melhores Sites Paraty RJ' />
      </Helmet>

      <Header />
      <Home />  
      <About />
      <TypeSites />
      <HowWorks />
      <Portfolio />
      <Rodape /> 
    </AppContainer>
  );
}

const AppContainer = styled.div`

  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

`;



export default App;
