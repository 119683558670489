import styled from "styled-components"
import hh from '../midia/harccpi.png'
import email from '../midia/icone_email.png'


function Rodape() {
    return (
        <div style={{borderTop: '2px solid rgba(0,0,0, .06)'}}>

        <Flex>
            <Grid>
                <img src={hh} width={150} className="logoHH"/>
                <p>
                    A HarpiHost é uma empresa especializada na criação de sites 
                    profissionais, dedicando-se ao design, desenvolvimento e 
                    gerenciamento de sites de alto impacto.
                </p>

                <div>
                    <button><a>{'(24) 99821-0584'}</a></button>
                    <div className="email" >
                        <img src={email} width={36}/><p> harpihost@gmail.com</p>
                    </div>
                </div>
                    
            </Grid>

            <Grid className="list">
                <h1>Serviços</h1>
                <p>Site sob medida</p>
                <p>Criação de sites baratos</p>
                <p>Criação de E-commerce</p>
            </Grid>
    
            <Grid className="list">
                <h1>Segmentos</h1>
                <p>Criação de Site para Médicos</p>
                <p>Criação de Site para Advogados</p>
                <p>Criação de Site para Restaurantes</p>
                <p>Criação de Site para Dentistas</p>
                <p>Criação de Site para Contabilidade</p>
            </Grid>
        </Flex>
        </div>

    )
}

const Grid = styled.li`
    display: grid;
    max-width: 40ch;
    justify-content: center;
    justify-items: start;
    gap: 1.8rem;
    padding: 0rem 2rem 2rem 2rem;
    align-items: center;
    align-content: start;
    line-height: 25px;
    
  

    p {
        color: rgba(0,0,0,.6);
        font-size: .88rem;
        font-weight: 400;
    }

    button {
        padding: .7rem 2rem;
        font-size: 1.1rem;
        border-radius: 2rem;
        border: none;
        font-weight: 600;
        background-color: rgba(0,0,0,.5);
        color: white;
    }

    img {
        position: relative;
        top: 40px;
        left: -40px;
    }
    
    * {
        padding: 0px;
        margin: 0px;
    }

    @media (max-width: 1000px) {
        padding: 0px;
        gap: 10px 0px;
    
        
        p {
            font-size: .8rem;
        }

        .logoHH{
            width: 120px;
            top: 20px;
            left: -20px;
        }

        button {
            padding: 13px 25px;
            font-size: 1rem;
        }
    }
    
`

const Flex = styled.ul`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    padding-bottom: 3rem;


    span {
        display: flex;
        gap: 0px;
    }

    div.email {
        padding-left: 35px;
    }

    .list {
        margin-top: 5rem;
        gap: 1.3rem;

        h1 {
            font-size: 1.2rem;
        }
    }

    @media (max-width: 1000px) {
        display: grid;
        padding: 0px 30px 30px 30px;
        gap: 20px 0px;
        
        .list {
            gap: 0px;
            margin: 0px;
            justify-content: start;
        }
    }



`

export default Rodape