import styled from "styled-components"
import img from "../paraty.jpg"
import { useEffect, useState } from "react";
import mso from '../midia/mso.png'

function About() {


    return (

        <Base>
            
            <h1>Alguns de Nossos Clientes</h1>
            
            <div>
                <ul>
                    
                    <li>
                        <a href="https://msosst.com.br/" target="_blank">
                            <div className="image"><img src={mso}/></div>
                            <h3 className="type">Empresa prestadora de serviços em SST</h3>
                            <h2 className="name">MSO - Medicina e Saúde Ocupacional</h2>
                        </a>
                    </li>

                    <li className="loading" >
                        <h2>Em Construção 😁</h2>
                    </li>

                    <li className="loading">
                        <h2>Em Construção 😁</h2>
                    </li>


                </ul>
            </div>
        </Base>

    )
}

const Base = styled.div`
    display: grid;
    padding: 100px 180px;

    margin-bottom: 5rem;

    h1{
        text-align: center;
    }

    ul {
        display: flex;
        justify-content: center;
        gap: 8rem;
    }

    div {
        margin-top: 2rem;
    }

    li {

        height: 300px;
        width: 330px;
        background-color: whitesmoke;
        border-radius: 1.5rem;
        overflow: hidden;
        position: relative;
        display: grid;
        place-content: start;
        gap: .2rem;
        margin: 0;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);

        *{
            margin: 0px;
        }

        a  {
            text-decoration: none;
            color: black;
        }

        .image {
            background-color: gainsboro;
            width: 100%;
            height: max-content;

            img{
                width: 100%;
            }
        }

        .type {
            background-color: greenyellow;
            width: 26ch;
            padding: .3rem .8rem;
            border-radius: 1rem;
            position: absolute;
            top: 170px;
            left: 10px;
            font-weight: bold;
            color: green;
            background-color: #ddfada;
            font-size: 1.1rem;
        }

        .what {
            font-size: 1rem;
            padding: 1.4rem 1rem 0rem 1rem;
            color: green;
            font-weight: bold;
        }

        .name {
            font-size: 1.3rem;
            padding: 2.6rem 1rem 0 1.5rem;
            color: #78866b;

        }
    }

    li.loading {
        text-align: center;
        justify-content: center;
        align-items: center;
        align-content: center;
        color: gray;
    }

    @media (max-width: 1300px) {
        
        ul {

            li.loading:last-child {
                display: none;
            }
        }
        
    }

    @media (max-width: 1000px) {
        padding: 40px 0 0 0;

        ul {
            padding: 0px;
            display: grid;
            gap: 50px;

            li.loading:last-child {
                display: none;
            }
        }

        li {
            width: 300px;
            height: 270px;


            .type {
                top: 156px;
                width: 19ch;
                color: green;
                background-color: #ddfada;
                padding: 3px 13px 3px 13px;
                left: 10px;
                font-size: .9rem;
            }
        }

        div.image {
            height: max-content;
        }

        h1 {
            font-size: 1.5rem;
            margin: 0px;
            padding: 1rem;
        }

        & > div {
            margin: 0px;
        }

        h2.name {
            font-size: 1.2rem;
            padding-top: 2.2rem;
        }
  
    }
`


export default About