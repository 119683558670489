import styled from "styled-components"
import { useEffect, useState } from "react";
import seta from "../midia/seta_black.png"
import ex from "../midia/dispositivos.png"
import React from 'react';
import bg1 from '../midia/bg1.jpg'
import bg2 from '../midia/bg2.jpg'
import bg3 from '../midia/bg3.jpg'
import bg4 from '../midia/bg4.jpg'

import contabilidade from '../midia/contabilidade.png'
import academia from '../midia/academia.png'
import restaurante from '../midia/restaurante.png'
import pousada from '../midia/pousada.png'
import advocacia from '../midia/advocacia.png'
import dentista from '../midia/dentista.png'
import medico from '../midia/medico.png'


function TypeSites() {

        const carouselRef = React.useRef(null);
    
        useEffect(() => {
            const carousel = carouselRef.current;
            const items = carousel.querySelectorAll('li');
            const itemCount = 3;
            let index = 0;
    
            const moveCarousel = () => {
                index++;
                if (index >= itemCount) {
                    index = 0;
                }
                carousel.style.transform = `translateX(-${index * 330}px)`;  // Ajuste o valor conforme necessário
            };
    
            const intervalId = setInterval(moveCarousel, 2500);  // Ajuste o tempo conforme necessário
    
            return () => clearInterval(intervalId);  // Limpeza do intervalo ao desmontar o componente
        }, []);

    return (

        <Bg >
            <div className="carousel">

            <Flex ref={carouselRef}>

                <li style={{backgroundImage: `url(${bg2})`}}>
                    <img src={contabilidade} width={45} style={{backgroundColor: '#FFFF99'}}/>
                    <h2>Site para<br /><span>Contabilidades</span></h2> 
                </li>

                <li style={{backgroundImage: `url(${bg2})`}}>
                    <img src={advocacia} width={45} style={{backgroundColor: '#FFFF99'}}/>
                    <h2>Site para<br /><span>Advocacias</span></h2> 
                </li>

                <li style={{backgroundImage: `url(${bg2})`}}>
                    <img src={restaurante} width={45} style={{backgroundColor: '#FFFF99'}}/>
                    <h2>Site para<br /><span>Restaurantes</span></h2> 
                </li>

                <li style={{backgroundImage: `url(${bg2})`}}>
                    <img src={pousada} width={45} style={{backgroundColor: '#FFFF99'}}/>
                    <h2>Site para<br /><span>Pousadas</span></h2> 
                </li>

                <li style={{backgroundImage: `url(${bg2})`}}>
                    <img src={dentista} width={45} style={{backgroundColor: '#FFFF99'}}/>
                    <h2>Site para<br /><span>Dentistas</span></h2> 
                </li>

                <li style={{backgroundImage: `url(${bg2})`}}>
                    <img src={medico} width={45} style={{backgroundColor: '#FFFF99'}}/>
                    <h2>Site para<br /><span>Médicos</span></h2> 
                </li>

            </Flex>
            </div>


        </Bg>
    )
      
}


const Flex = styled.ul`
    list-style: none;
    display: flex;
    gap: 3.35rem;
    padding: 0px;
    transition: transform 0.9s ease-in-out;  /* Adiciona transição suave */

    img {
        background-color: white;
        padding: 1rem;
        border-radius: 1.3rem;
    }

    li {
        background-size: cover; /* Ajusta a imagem para cobrir o contêiner */
        padding: 1rem;
        border-radius: 1.3rem;
        width: 240px;
        flex-shrink: 0;  /* Impede que os itens encolham */
        color: aliceblue;
        font-size: 1rem;
        padding: 1.5rem;

        h2 {
            font-weight: 500;
            margin: 0px;

            span {
                font-weight: bold;
            }
        }
    }

    @media (max-width: 1300px) {
        padding-left: 3rem;
        gap: 3.7rem;

        li {
            width: 200px;
        }


    }

    @media (max-width: 450px) {
        gap: 1.6rem;
        margin: 1rem;
        padding-left: 0px;


        img {
            width: 30px;
        }

        li {
            font-size: .7rem;
            width: 100px;
            padding: 1rem 2.3rem 1rem 1rem;
        }
    }

`
const Bg = styled.div`
    max-width: 1360px;
    margin: 0 auto;
    padding: 5rem 0;
    display: flex;
    gap: 1rem;
    overflow-x: hidden;  /* Usar auto para permitir rolagem horizontal */
    white-space: nowrap;  /* Impede a quebra de linha dos itens filhos */
    border-top: 2px solid rgba(0,0,0, .05);
    margin-top: 4rem;


    button {
        background: none;
        border: none;
        font-size: 3rem;
    }

    @media (max-width: 450px) {
        padding: 2rem 0;
    }
`
export default TypeSites