import styled from "styled-components"
import { useEffect, useState } from "react";
import img from "../midia/paratytest1.jpg"
import setaEstado1 from '../midia/seta estado 1.png'
import setaEstado2 from '../midia/seta estado 2.png'
import hh from '../midia/harccpi.png'

 

function Home() {

    const [hasScrolled, setHasScrolled] = useState(false);
    const [slogan, setSlogan] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const triggerPoint = 10; 

            
            if (scrollPosition > triggerPoint) {
                setHasScrolled(true);
                
                console.log(true)
            } else {
                setHasScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
          setSlogan(prevSlogan => !prevSlogan)
            
          console.log(slogan)
        }, 2900);
        


        // Limpeza do intervalo quando o componente é desmontado
        return () => clearInterval(intervalId);
      }, []); 

    return (
        <div style={{zIndex: 10}}>
            


            <Slogan>

                <div className="um" style={slogan ? {opacity: '0'}: {opacity: '1'}}>
                    <h1>Desenvolvimento e Mantimento<br /> de Web Sites em Paraty</h1>
                </div>

                <div className="dois" style={slogan ? {opacity: '1'}: {opacity: '0'}}>
                    <h1>Todos os dias pessoas pesquisam por empresas no Google. <strong>E a sua, aparece? </strong></h1>
                </div>

            </Slogan>


        
            <Seta style={hasScrolled ? {opacity: 0}: {opacity: 1}}>
                <img src={setaEstado2} width={70} />
            </Seta>


           
           <Div style={ hasScrolled ? null : {padding: 0} }>
                <Base>
                </Base>  
           </Div>
        
        </div>

    )       
}


const Seta = styled.div`
    position: absolute;
    bottom: 1%;
    width: 100%;
    text-align: center;
    animation: moveRight 2s linear infinite;

    

    @keyframes moveRight {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-20px);
        }
        100% {
            transform: translateY(0);
        }
    }

    @media (max-width: 450px) {
        img {
            width: 50px;
        }
    }
`

const Div = styled.div`
    transition: .3s;
    padding: 0 180px 150px 180px;

    @media (max-width: 1500px) {
        padding: 0 50px 80px 50px;
    }

    @media (max-width: 450px) {
        padding: 0 20px 40px 20px;
    }
`

const Slogan = styled.div`
    position: absolute;
    width: 100%;
    justify-content: center;
    height: 100%;
    top: 380px;
    align-items: center;
    text-align: center;
    color: aliceblue;

    h1 {
        font-size: 2.3rem;
        font-weight: 500;
    }

    p{
        font-size: 1.2rem;
        color: gainsboro;
        padding: 1rem 0 0 0 ;
        max-width: 40ch;
        margin: 0 auto;
    }

    * {
        height: max-content;
        margin: 0px;
        padding: 0rem;
    }

    div {
        transition: 1s;
    }

    .um {
        position: relative;
        bottom: -50px;
    }

    .dois {
        margin: 0 auto;
        display: grid;
        justify-content: center;
        position: relative;
        justify-items: center;
        max-width: 100ch;
        gap: 1.3rem;
        top: -38px;

    }

    @media (max-width: 1000px) {
        h1 {
            font-size: 1.7rem;
            max-width: 25ch;
            margin: 0 auto;
        }
        
    }


    @media (max-width: 450px) {
        top: 170px;

        h1 {
            font-size: 1.5rem;
            padding: 0 1rem;
        }
        
        p {
            font-size: .9rem;
        }
        
        .um {
            bottom: -160px;
        }
        .dois {
            max-width: 35ch;
            top: 70px;
        }
    }

`

const Base = styled.div`
    display: flex;
    justify-content: center;
    justify-items: center;
    height: 100vh;
    top: 0px;
    background-image: url(${img});
    background-position: center;
    background-size: cover; /* Ajusta a imagem para cobrir o contêiner */
    box-sizing: border-box; /* Inclui o padding no cálculo do tamanho do elemento */


`
export default Home