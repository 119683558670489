import styled from "styled-components"
import { useEffect, useState } from "react";
import seta from "../midia/seta_black.png"
import devices from "../midia/dispositivos.png"
import settings from "../midia/settings.png"
import send from "../midia/aviao.png"


function HowWorks() {


    return <Flex>
        <li>
            <img src={devices} width={40}/>
            <h1>Escolha o tipo de site</h1>
            <p>Site sob medida. Ajudamos a definir o tipo de site ideal para você!</p>

        </li>
        <li>
            <img src={settings} width={40}/>
            <h1>Atualizações</h1>
            <p>Escolha os adicionais do seu site. Atualizações periódicas mantendo uma alta qualidade</p>

        </li>
        <li>
            <img src={send} width={40}/>
            <h1>Pronto para decolar</h1>
            <p>Emissão de contrato, briefing e mãos na massa! Em poucos dias um site novinho em folha!</p>

        </li>
    </Flex>
        
      
}


export default HowWorks

const Flex = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: space-between;
    gap: 3rem;
    padding: 100px 0 50px 0;
    max-width: 1300px;
    margin: 0 auto;

    li {
        width: 400px;
        display: grid;
        align-items: center;
        justify-items: center;
        text-align: center;
        gap: .8rem;

        p {
            color: rgba(0,0,0,.7);
            max-width: 25ch;
            line-height: 1.5rem;
            font-size: .9rem;
        }

        * {
            margin: 0px;
        }
    }

    h1 {
        font-size: 1.5rem;
    }

    @media (max-width: 750px) {
        display: grid;
        justify-content: center;
        width: max-content;
        padding: 4rem 0 4rem 0;
        
        li {
            width: 300px;
            grid-template-columns: max-content 1fr;
            justify-items: left;
            gap: 0 2rem;

            h1 {
                font-size: 1.1rem;
            }

            p {
                grid-column: 2;
                text-align: left;
                font-size: .8rem;
                line-height: 18px;
                max-width: max-content;
            }

            img {
                grid-row: 1/3;
                width: 30px;
            }
        }
    }

`